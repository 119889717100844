// import { NetworkIds } from 'blockchain/networks'
import { AppLink } from 'components/Links'
import { AssetsTableDataCellAction } from 'components/assetsTable/cellComponents/AssetsTableDataCellAction'
// import { AssetsTableDataCellAsset } from 'components/assetsTable/cellComponents/AssetsTableDataCellAsset'
// import { AssetsTableRowData } from 'components/assetsTable/types'
// import { ProtocolLabel } from 'components/ProtocolLabel'
// import { getActionUrl, parseProduct } from 'features/productHub/helpers'
// import { ProductHubItem, ProductHubProductType } from 'features/productHub/types'
// import { upperFirst } from 'lodash'
import React from 'react'
import { Box, Button, Flex, Image, Text } from 'theme-ui'

// export function parseRows(
//   rows: ProductHubItem[],
//   product: ProductHubProductType,
//   chainId?: NetworkIds,
// ): AssetsTableRowData[] {
//   return rows.map((row) => {
//     const { depositToken, label, network, primaryToken, protocol, reverseTokens, secondaryToken } =
//       row
//     const icons = primaryToken === secondaryToken ? [primaryToken] : [primaryToken, secondaryToken]
//     const asset = product === ProductHubProductType.Earn ? depositToken || primaryToken : label

//     if (reverseTokens) icons.reverse()

//     const url = getActionUrl({ ...row, chainId, product: [product] })
//     const urlDisabled = url === '/'

//     return {
//       [product === ProductHubProductType.Earn ? 'depositToken' : 'collateralDebt']: (
//         <AssetsTableDataCellAsset asset={asset} icons={icons} />
//       ),
//       ...parseProduct(row, product),
//       protocolNetwork: (
//         <ProtocolLabel
//           network={Array.isArray(network) ? network[0] : network}
//           protocol={protocol}
//         />
//       ),
//       action: (
//         <AssetsTableDataCellAction
//           cta={urlDisabled ? 'Coming soon' : upperFirst(product)}
//           link={url}
//           disabled={urlDisabled}
//         />
//       ),
//     }
//   })
// }

export function parseRows(rows: any[], address?: string) {
  return rows.map((row) => {
    return {
      Collateral: (
        <Flex alignItems="center">
          {row.type === 'dLP' && (
            <img
              src="/static/icons/tokens/ETH.svg"
              style={{ width: 25, marginRight: '-10px', zIndex: 3 }}
            />
          )}
          <img
            src={`/static/icons/tokens/${row.type === 'ETH' ? 'stETH' : row.type}.svg`}
            style={{ width: 25 }}
          />
          <Text ml="12px" sx={{ fontWeight: 500 }}>
            {row.label}
          </Text>
        </Flex>
      ),
      TVL: row.TVL,
      APR: (
        <Flex
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            '@media screen and (max-width: 68em)': { justifyContent: 'flex-start' },
          }}
        >
          {row.APR}
          <Box
            style={{ position: 'relative' }}
            sx={{
              '.info': {
                display: 'none',
                minWidth: '140px',
                fontSize: '12px',
                color: '#596F78',
                position: 'absolute',
                right: 0,
                top: '25px',
                background: '#F8F9FF',
                zIndex: '3',
              },
              '@media screen and (max-width: 68em)': {
                '.info': {
                  left: 0,
                  minWidth: '200px',
                },
              },
              '&:hover': { '.info': { display: 'block' } },
            }}
          >
            <Image src="/static/icons/info.svg" ml="8px" style={{ cursor: 'pointer' }} />
            <div className="info">
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  background: '#F1F3FD',
                  borderRadius: '6px 6px 0px 0px',
                  padding: '8px',
                }}
              >
                <Text sx={{ fontWeight: '400' }}>Rewards</Text>
                <Text sx={{ fontWeight: '500' }}>APR</Text>
              </Flex>
              {row.APRComponents && row.type === 'dLP' ? (
                <>
                  <Flex
                    sx={{
                      justifyContent: 'space-between',
                      padding: '8px',
                    }}
                  >
                    <Text sx={{ fontWeight: '400' }} ml="4px">
                      eUSD sharing
                    </Text>
                    <Text sx={{ fontWeight: '500' }}>
                      {(parseFloat(row.APRComponents.eusd) * 100).toFixed(2)}%
                    </Text>
                  </Flex>
                  {/*
                  <Flex
                    sx={{
                      justifyContent: 'space-between',
                      padding: '8px',
                    }}
                  >
                    <Text sx={{ fontWeight: '400' }} ml="4px" mr="8px">
                      Match treasury sharing
                    </Text>
                    <Text sx={{ fontWeight: '500' }}>
                      {(parseFloat(row.APRComponents.match_treasury) * 100).toFixed(2)}%
                    </Text>
                  </Flex>
                  */}
                  <Flex
                    sx={{
                      justifyContent: 'space-between',
                      padding: '8px',
                    }}
                  >
                    <Text sx={{ fontWeight: '400' }} ml="4px">
                      dLP
                    </Text>
                    <Text sx={{ fontWeight: '500' }}>
                      {(parseFloat(row.APRComponents.dlp) * 100).toFixed(2)}%
                    </Text>
                  </Flex>
                  <Flex
                    sx={{
                      justifyContent: 'space-between',
                      padding: '8px',
                    }}
                  >
                    <Text sx={{ fontWeight: '400' }} ml="4px">
                      dLP swap fee
                    </Text>
                    <Text sx={{ fontWeight: '500' }}>
                      {(parseFloat(row.APRComponents.dLPSwapFee) * 100).toFixed(2)}%
                    </Text>
                  </Flex>
                  {/* <Flex
                    sx={{
                      justifyContent: 'space-between',
                      padding: '8px',
                    }}
                  >
                    <Text sx={{ fontWeight: '400' }} ml="4px">
                      Airdrop
                    </Text>
                    <Text sx={{ fontWeight: '500' }}>{row.APRComponents.airdrop?.toFixed(2)}%</Text>
                  </Flex> */}
                </>
              ) : row.APRComponents ? (
                <>
                  <Flex
                    sx={{
                      justifyContent: 'space-between',
                      padding: '8px',
                    }}
                  >
                    <Flex>
                      <Text sx={{ fontWeight: '400' }} ml="4px">
                        Mint Pool
                      </Text>
                    </Flex>
                    <Text sx={{ fontWeight: '500' }}>{row.APRComponents.mintPool}</Text>
                  </Flex>
                  <Flex
                    sx={{
                      justifyContent: 'space-between',
                      padding: '8px',
                      borderRadius: '0px 0px 6px 6px',
                    }}
                  >
                    <Flex>
                      <Text sx={{ fontWeight: '400' }} ml="4px">
                        Shared
                      </Text>
                    </Flex>
                    <Text sx={{ fontWeight: '500' }}>{row.APRComponents.shared}</Text>
                  </Flex>

                  <Flex
                    sx={{
                      justifyContent: 'space-between',
                      padding: '8px',
                      borderRadius: '0px 0px 6px 6px',
                    }}
                  >
                    <Flex>
                      <Text sx={{ fontWeight: '400' }} ml="4px" mr="4px">
                        eUSD rebase
                      </Text>
                    </Flex>
                    <Text sx={{ fontWeight: '500' }}>{row.APRComponents.eUSDRebase}</Text>
                  </Flex>
                  {/* <Flex
                    sx={{
                      justifyContent: 'space-between',
                      padding: '8px',
                    }}
                  >
                    <Text sx={{ fontWeight: '400' }} ml="4px">
                      Airdrop
                    </Text>
                    <Text sx={{ fontWeight: '500' }}>{row.APRComponents.airdrop?.toFixed(2)}%</Text>
                  </Flex> */}
                </>
              ) : null}
            </div>
          </Box>
        </Flex>
      ),
      Balance: row.Balance,
      Rewards: row.Rewards,
      action: (
        <AssetsTableDataCellAction
          cta={row.type == 'dLP' ? 'Stake' : 'Supply'}
          link={`/earn/dsr/${row.type}`}
        />
      ),
    }
  })
}

function TableButton({ children, sx, primary, onClick, disabled }: any) {
  return (
    <Flex
      sx={{
        width: '81px',
        height: '36px',
        borderRadius: '32px',
        border: '1px solid',
        borderColor: primary ? '#5065E4' : '#EAEAEA',
        color: primary ? '#5065E4' : '#596F78',
        fontSize: '12px',
        fontWeight: 500,
        cursor: disabled ? '' : 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
          background: '#F1F3F4',
        },
        ...(sx || {}),
      }}
      onClick={disabled ? null : onClick}
    >
      {children}
    </Flex>
  )
}
export function parseEarnRows(rows: any[], matchPrice: number, lbrPrice: number) {
  console.log(rows)
  let rewards = rows[1].Rewards
  if (!rows[1].Rewards) rewards = { mesLbr: 0, vlMatch: 0, usdc: 0 }
  if (!rows[0].Rewards) rows[0].Rewards = { mesLbr: 0, usdc: 0 }
  rows[1].lbrRewards = rewards.mesLbr * lbrPrice || 0
  rows[1].vlMatchRewards = rewards.vlMatch * matchPrice || 0
  rows[1].usdcRewards = rewards.usdc || 0
  rows[0].lbrRewards = rows[0].Rewards.mesLbr * lbrPrice || 0
  rows[0].usdcRewards = rows[0].Rewards.usdc || 0
  rows[1].totalRewards = rows[1].lbrRewards + rows[1].vlMatchRewards + (rewards.usdc as number)
  rows[0].totalRewards = rows[0].Rewards.mesLbr * lbrPrice + (rows[0].Rewards.usdc as number)
  console.log(rows[0].Rewards.meslbr * lbrPrice, rows[0].Rewards.usdc)
  const newRows = rows.map((row, index) => {
    return {
      Pool: (
        <Flex sx={{ alignItems: 'center', mr: '-65px' }}>
          <img
            src={`/static/icons/${row.Pool === 'mesLBR' ? 'tokens/dLP.svg' : 'match_logo.svg'}`}
            style={{ width: 25 }}
          />
          <Text ml="12px" sx={{ fontWeight: 500 }}>
            {row.Pool}
          </Text>
        </Flex>
      ),
      'Total Staked': `${parseFloat(row.TotalStaked.toFixed(2)).toLocaleString('en-US')}`,
      Balance: `${parseFloat(row.Balance.toFixed(4)).toLocaleString('en-US')}`,
      APR: (
        <Flex
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            '@media screen and (max-width: 68em)': { justifyContent: 'flex-start' },
          }}
        >
          {row.APR}
          <Box
            style={{ position: 'relative' }}
            sx={{
              '.info': {
                display: 'none',
                minWidth: '130px',
                fontSize: '12px',
                color: '#596F78',
                position: 'absolute',
                right: 0,
                top: '25px',
                background: '#F8F9FF',
                zIndex: '3',
              },
              '@media screen and (max-width: 68em)': {
                '.info': {
                  left: 0,
                  minWidth: '200px',
                },
              },
              '&:hover': { '.info': { display: 'block' } },
            }}
          >
            <Image src="/static/icons/info.svg" ml="8px" style={{ cursor: 'pointer' }} />
            <div className="info">
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  background: '#F1F3FD',
                  borderRadius: '6px 6px 0px 0px',
                  padding: '8px',
                }}
              >
                <Text sx={{ fontWeight: '400' }}>Rewards</Text>
                <Text sx={{ fontWeight: '500' }}>APR</Text>
              </Flex>
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  padding: '8px',
                }}
              >
                <Text sx={{ fontWeight: '400' }}>Base</Text>
                <Text sx={{ fontWeight: '500' }}>
                  {row.APRComponents.base ? row.APRComponents.base : '--'}
                </Text>
              </Flex>
              {/* <Flex
                sx={{
                  justifyContent: 'space-between',
                  padding: '8px',
                }}
              >
                <Text sx={{ fontWeight: '400' }}>Airdrop</Text>
                <Text sx={{ fontWeight: '500' }}>
                  {row.APRComponents.airdrop ? row.APRComponents.airdrop : '--'}
                </Text>
              </Flex> */}
            </div>
          </Box>
        </Flex>
      ),
      Staked: `${parseFloat(row.Staked.toFixed(4)).toLocaleString('en-US')}`,
      Rewards: (
        <Flex
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'center',
            '@media screen and (max-width: 68em)': { justifyContent: 'flex-start' },
          }}
        >
          ${row.totalRewards.toFixed(2)}
          <Box
            style={{ position: 'relative' }}
            sx={{
              '.info': {
                display: 'none',
                minWidth: '130px',
                fontSize: '12px',
                color: '#596F78',
                position: 'absolute',
                right: 0,
                top: '25px',
                background: '#F8F9FF',
                zIndex: '3',
              },
              '@media screen and (max-width: 68em)': {
                '.info': {
                  left: 0,
                  minWidth: '200px',
                },
              },
              '&:hover': { '.info': { display: 'block' } },
            }}
          >
            <Image src="/static/icons/info.svg" ml="8px" style={{ cursor: 'pointer' }} />
            <div className="info">
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  background: '#F1F3FD',
                  borderRadius: '6px 6px 0px 0px',
                  padding: '8px',
                }}
              >
                <Text sx={{ fontWeight: '400' }}>Rewards</Text>
              </Flex>
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  padding: '8px',
                }}
              >
                <Text sx={{ fontWeight: '400' }}>mesLBR</Text>
                <Text sx={{ fontWeight: '500' }}>${row.lbrRewards.toFixed(2)}</Text>
              </Flex>
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  padding: '8px',
                }}
              >
                <Text sx={{ fontWeight: '400' }}>USDC</Text>
                <Text sx={{ fontWeight: '500' }}>${row.usdcRewards.toFixed(2)}</Text>
              </Flex>
              {/* {row.vlMatchRewards && (
                <Flex
                  sx={{
                    justifyContent: 'space-between',
                    padding: '8px',
                  }}
                >
                  <Text sx={{ fontWeight: '400' }}>vlMatch</Text>
                  <Text sx={{ fontWeight: '500' }}>${row.vlMatchRewards.toFixed(2)}</Text>
                </Flex>
              )} */}
            </div>
          </Box>
        </Flex>
      ),
      action: (
        <Flex sx={{ justifyContent: 'flex-end', ml: '-80px' }}>
          <TableButton onClick={row.onStake}>Stake</TableButton>
          <TableButton sx={{ ml: '10px' }} onClick={row.onVest}>
            {row.Pool === 'mesLBR' ? 'Unstake' : 'Vest'}
          </TableButton>
          <TableButton sx={{ ml: '10px' }} onClick={row.onClaim} disabled={row.claiming}>
            {row.claiming ? 'Claiming' : 'Claim'}
          </TableButton>
          {/* <TableButton sx={{ ml: '10px', position: 'relative', width: '90px' }} disabled={true}>
            Claim
            <Box
              style={{ position: 'relative' }}
              sx={{
                '.info': {
                  display: 'none',
                  padding: '4px 8px',
                  fontSize: '12px',
                  color: '#596F78',
                  position: 'absolute',
                  borderRadius: '6px',
                  left: '-120px',
                  bottom: '30px',
                  background: '#F8F9FF',
                  zIndex: '3',
                },
                '&:hover': { '.info': { display: 'block' } },
              }}
            >
              <Image
                src="/static/icons/info.svg"
                ml="4px"
                style={{ width: '11px', cursor: 'pointer' }}
              />
              <div className="info">Coming soon</div>
            </Box>
          </TableButton> */}
          <AppLink href={row.buyLink} sx={{ ml: '10px' }}>
            <TableButton primary>Buy</TableButton>
          </AppLink>
        </Flex>
      ),
    }
  })
  console.log(newRows)
  return newRows
}
export function parseVestingScheduleRows(rows: any[], onSelect: (index: number) => void) {
  const selectedNum = rows.filter((item) => item.selected).length
  return rows.map((row, index) => {
    // const days = Math.floor(row.remainDuration / 86400)
    return {
      Select: (
        <Image
          src={`/static/icons/${row.selected ? 'check_box_active' : 'check_box'}.svg`}
          sx={{
            cursor: selectedNum < 10 || row.selected ? 'pointer' : 'not-allowed',
            opacity: selectedNum < 10 || row.selected ? '1' : '0.6',
            mb: '-6px',
          }}
          onClick={() => (selectedNum < 10 || row.selected ? onSelect(row.index) : null)}
        />
      ),
      'NO.': row.index > 8 ? (row.index as number) + 1 : `0${(row.index as number) + 1}`,
      'Remaining Duration': (
        <Text>
          <span style={{ color: '#5065E4' }}>{Math.floor(row.remainDuration / 86400)}</span>days
          <span style={{ color: '#5065E4' }}>
            {' '}
            {Math.floor((row.remainDuration % 86400) / 3600)}{' '}
          </span>
          hours
        </Text>
      ),
      Amount: row.initVest,
      Penalty: `${row.penaltyRewards.toLocaleString('en-US')}`,
    }
  })
}
